/* Add your styles here */
.login-container {
    padding: 24px; /* Increased padding */
    max-width: 100vh; /* Increased max-width */
    margin: auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .logo {
    width: 300px;
    height: 100px;
  }
  body {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    height: 100vh; /* Make sure the body takes the full height of the viewport */
    background-size: cover; /* Adjust as needed */
    background-position: center; /* Adjust as needed */
    background-color: #f0f0f5;
  }
  .additional-image {
    width: 150px;
    height: 45px;
  }